export interface BookDecorationQuiz {
    chapter: number;
    desc: string;
    answer: string | string[];
    type: BookDecorationQuizType;
    id: number;
}

export enum BookDecorationQuizType {
    FillBlack
}

export const BookDecorationQuizs: BookDecorationQuiz[] = [
    ...[
        { answer: ['材料'], desc: '选择印刷工艺的依据首先是印刷$。' },
        { answer: ['170'], desc: '米卡纸定量比白卡纸稍小，在$～200g/m2之间。' },
        { answer: ['纤维'], desc: '合成纸分为两大类，一类是$型合成纸，另一类是薄膜系合成纸。' },
        { answer: ['商标用'], desc: '不干胶按其应用基本分为三类：$标签、广告宣传用招贴及装潢用材料。' },
        { answer: ['应用'], desc: '电化铝可以按照颜色和$领域来分类。' },
        { answer: ['顺序号'], desc: '彩票在制作过程中，除了彩色画面外，还设置了$、兑奖区、保安验证区。' },
        { answer: ['胶印'], desc: '以纸作为承印物，$印刷工艺是首选。' },
        { answer: ['高低瓦楞'], desc: ' U形瓦愣的结构特点使其在实际生产中瓦愣磨损小，不易出现$和断愣现象。' },
        { answer: ['制法'], desc: '合成纸的性能取决于它的原材料和$。' },
        { answer: ['金属'], desc: '烫金的最大特点是独特的$光泽和强烈的视觉对比。' },
        { answer: ['光泽'], desc: '烫金与印金相比，烫金的$度和质感要比印金强得多。泽' },
        { answer: ['柔性'], desc: '铝箔纸不干胶卷筒纸印刷主要采用$版印刷。' },
        { answer: ['特种'], desc: '复合包装印刷属于$印刷的范畴。' },
        { answer: ['上光'], desc: '如果在样本封面、封底做大面积印金或印银，则以$替代覆膜为好。' },
        { answer: ['双层', '锁定'], desc: '装配式纸盒按照其结构可分为$式和$式两种形式。' },
        { answer: ['容积'], desc: '纸的吸墨性（空隙率）表示了纸张与纸板中的空气$的比例。' },
        { answer: ['反射'], desc: '光泽度是指纸张与纸板表面对光的$情况。' },
        { answer: ['胶料'], desc: '纸张和纸板的表面强度是指纤维、填料、$三者之间的结合强度。' },
        { answer: ['纸箱'], desc: '瓦楞纸板主要用于制作$和纸盒。' },
        { answer: ['弹性'], desc: 'V形瓦楞纸板的缺点是一旦受力超过$极限，就完全丧失恢复力。' },
        { answer: ['防潮性'], desc: '铝箔具有良好的光泽和阻气$及保味性。' },
        { answer: ['抗张强度'], desc: '纸包装容器在承载重物时，$起着重要作用。' },
        { answer: ['勒口'], desc: '在设计书刊或课本封面覆膜时应设计$，以防翘起。' },
        { answer: ['多色'], desc: '局部上光的工艺一般在$印刷机上进行。' },
        { answer: ['金色'], desc: '电化铝按颜色分类，最常用的是$。' },
        { answer: ['珠光或金属'], desc: '光变油墨是一种反射性油墨，具有$效应。' },
        { answer: ['150'], desc: '彩票印刷的承印物通常是$g以上的卡纸或铜版纸。' },
        { answer: ['单面'], desc: '常规的不干胶印刷是指纸类不干胶$印刷。' },
        { answer: ['非特征'], desc: '磁性油墨分特征性和$性两种。' },
        { answer: ['造型'], desc: '印刷品作为一种视觉传达，它的语言是：色彩、线条、质感、$。' },
        { answer: ['成本'], desc: '印刷设计师必须熟悉各种印后加工的效果、适性以及$。' },
        { answer: ['材料'], desc: '印刷设计从程序上来说第一步就是选择印刷$，明确这一点非常重要。' },
        { answer: ['扩张'], desc: '纸包装容器在承载重物时，$强度起着重要作用。' },
        { answer: ['抗弯曲'], desc: '瓦楞纸箱质量的一个重要指标就是$能力与耐压能力。' },
        { answer: ['纤维'], desc: '纸的吸墨性主要取决于纸张与纸板$间的空隙大小，即纸的空隙率。' },
        { answer: ['7'], desc: '含有一定的水分，一般含水量约为$%。' },
        { answer: ['变脆'], desc: '如果含水量太低，纸张和纸板会$。' },
        { answer: ['卷曲'], desc: '如果纸张和纸板的含水不均匀，会引起$、起皱现象。' },
        { answer: ['环境湿度'], desc: '纸张和纸板含水量的变化受$和温度变化的影响。' },
        { answer: ['涂料'], desc: '铜版纸的主要原料是铜版原纸（又称纸坯）与$。' },
        { answer: ['新闻'], desc: '普通铜版纸采用优质的$纸或胶版纸加工。' },
        { answer: ['平滑'], desc: '采用不同的原纸制成的铜版纸在性能上的主要差别是$度与白度的不同。' },
        { answer: ['800'], desc: '高级铜版纸的平滑度可达$s以上。' },
        { answer: ['扩大'], desc: '铜版纸的另一个特点是网点$值较小。' },
        { answer: ['柔性'], desc: '瓦楞纸板的印刷方式以$ 版印刷为主。' },
        { answer: ['定位'], desc: '一般情况下，印刷设计师应该在确定设计$后介入。' },
        { answer: ['纸板'], desc: '广义上，纸包括纸张与$。' },
        { answer: ['正比'], desc: '在理论上，纸的挺度与其厚度的三次方成$。' },
        { answer: ['碱溶'], desc: '水基油墨可分为水溶性、$性与扩散性三种。' },
        { answer: ['孔版'], desc: '丝网印刷是$印刷的印刷方法之一。' },
        { answer: ['23.8'], desc: '我国的标准模切刀高度为$mm。' },
        { answer: ['激光'], desc: '20世纪60年代出现的$技术，是实现全息照相的前提。' },
        { answer: ['白卡纸'], desc: '所有手提袋中强度最高的是$手提袋。' },
        { answer: ['卷筒'], desc: '不干胶标签用$纸的方式印刷，是目前常用的印刷方式。' },
        { answer: ['专色'], desc: '标签印刷与常规印刷的最大区别是$应用的非常普遍。' },
        { answer: ['软包装'], desc: '常用的复合包装材料.其中以塑料薄膜为主的$材料在包装印刷中占有重要地位。' },
        { answer: ['跨页'], desc: '样本$印刷时，必须要求规格统一，色调一致。' },
        { answer: ['纸类'], desc: '胶印的承印物基本上是各种$印刷品。' },
        { answer: ['印后加工'], desc: '印刷设计师要了解$手段在各种承印材料上所呈现的不同效果。' },
        { answer: ['定量', '厚度'], desc: '从定性的角度看，纸的$与$成正比。' },
        { answer: ['普通'], desc: '合成纸的成本高于$纸。' },
        { answer: ['组合', '轮转'], desc: '凹版印刷主要是由$式凹版$机承担。' },
        { answer: ['四色', '十二'], desc: '标签印刷机都在$以上，最多有$色印刷。' },
        {
            answer: ['色彩', '图形'],
            desc: '在食品包装的装潢设计中，将原料、未加工的制品等用照片表现，$与$构成了商品的形象。'
        },
        { answer: ['印张', '基数'], desc: '用纸量一般采用$计算法或$计算法计算。' },
        { answer: ['批量'], desc: '印刷设计师需要特别清晰地了解各种印刷工艺的特点、成本、适用的$。' },
        {
            answer: ['平滑', '度与', '白度'],
            desc: '采用不同的原纸制成的铜版纸在性能上的主要差别是$$$ 的不同。'
        },
        { answer: ['网线'], desc: '铝箔纸不干胶印刷应尽量采用$印刷。' },
        { answer: ['卷筒', '柔性'], desc: '铝箔纸不干胶$纸印刷主要采用$版印刷' },
    ].map((item) => ({ ...item, chapter: 0, type: BookDecorationQuizType.FillBlack })),
].map((quiz, index) => ({
    ...quiz,
    id: index + 1
}))
