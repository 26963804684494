export interface Noun {
    title: string;
    explanation: string;
    id: number;
}

export const nouns: Noun[] = [
    {
        title: '理想白',
        explanation: '理论上的白是指能百分之百反射入射光的物体，称为理想白'
    },
    {
        title: '单面瓦楞纸板',
        explanation: '即一张垫纸与一张瓦楞纸粘合而成，作包装衬垫用'
    },
    {
        title: '凹凸加工',
        explanation: '凹凸加工，利用称原因无受压力产生可塑性变形的原理，通过制作凹凸模板，施加一定压力，是承印物表面产生明显的凹凸立体层次的效果的过程，即为凹凸加工俗称轧凹凸'
    },
    {
        title: '局部上光',
        explanation: '所谓局部上光（消光），是指只对印刷版面的某一局部区域进行上光或消光'
    },
    {
        title: '防伪油墨',
        explanation: '防伪印刷设计中，为了降低成本，充分利用现行的设备工艺，一种有效的方法是选用具有特殊功能的防伪油墨。这种油墨通常被称为防伪油墨或安全油墨'
    },
    {
        title: '特征性磁性油墨',
        explanation: '是指油墨中磁粉只有特定的磁信号；而非特征性磁性油墨则没有磁峰固定的磁信号，仅有磁性而已'
    },
    {
        title: '裂断长度',
        explanation: '裂断长度是一定宽度的纸条在身体重力作用下，将纸拉断时的长度'
    },
    {
        title: '涤纶丝网',
        explanation: '涤纶丝网是由聚酯纤维织成。它的优点是稳定性好、耐机械力、化学力及耐候性均优于尼龙网；绷网张力高，平面稳定性及离网印刷性能也好于尼龙网。能用于高精度丝印'
    },
    {
        title: '姐妹纸盒',
        explanation: '姐妹纸盒是以两个或以上相同造型的纸盒在一张纸上折叠而成的，其造型有趣、可爱，适合于盛放礼品和化妆品'
    },
    {
        title: '纸张',
        explanation: '按照造纸工业标准，定量小于225g/m2的纸页称为纸张'
    },
    {
        title: '波前记录',
        explanation: '全息照相的整个过程分为两步：拍摄全息照片称为波前记录'
    },
    {
        title: '波前再现',
        explanation: '全息照相的整个过程分为两步：再现全息照片称为波前再现'
    },
    {
        title: '厚度',
        explanation: '是纸张、纸板的厚薄度。通常以mm为计量单位'
    },
    {
        title: '挺度',
        explanation: '是表示纸张与纸板的抗弯曲强度的性能'
    },
    {
        title: '纸的压缩性',
        explanation: '纸张与纸板在压力作用下会发生压缩，取消压力后会恢复到原来状态，这种特征称为纸的压缩性'
    },
    {
        title: '水基油墨',
        explanation: '是指以水、醇、溶纤剂等为溶剂的柔性版印刷油墨，主要用于吸收性较强的承印材料的印刷'
    },
    {
        title: '尼龙丝网',
        explanation: '它是由人工合成的聚酰胺纤维编织而成，具有很高的强度和耐磨性，不仅印品的印迹鲜明，也适合不规则表面丝印；缺点是延伸率大，且弹性模量小，不适合高精度产品丝印'
    },
    {
        title: '定量',
        explanation: '纸张、纸板每平方米的质量称为定量'
    },
    {
        title: '纸的吸水性',
        explanation: '是指纸张或纸板对油性物质与水性物质的吸收能力。印刷时也常称为吸墨性'
    },
    {
        title: 'UV油墨',
        explanation: '是一种紫外线干燥油墨，它是一种在紫外线照射下，迅速完成从液态转化为固态的油墨'
    },
    {
        title: '单片式样本',
        explanation: '单片式是一种简易的印刷宣传品，常见的有招工、招生、短期学习班、商品介绍等。单片为32开、16开较多，因为此尺寸携带方便，也经济实惠'
    },
    {
        title: '纸板',
        explanation: '按照造纸工业标准，定量大于225g/m2的纸页称为纸板'
    },
    {
        title: '抗张强度',
        explanation: '是指一定宽度的纸可能承受的最大张力或拉力。抗张强度的单位是kN/m'
    },
    {
        title: '签样',
        explanation: '胶印印刷在大规模正式开印前，需要有一个环节来保证印件的色彩、规格与样张及施工单位的要求相符合，这样能有效地避免一旦出错造成大量浪费的情况出现，这一环节就是签样'
    },
    {
        title: '不锈钢丝网',
        explanation: '是强度和弹性模量最大的一种丝网，故平面稳定性好，丝径小，能织高目数丝网，所以能用于精度丝印'
    },
    {
        title: '上光',
        explanation: '是印刷品表面的另外一种光泽处理技术，是在印刷品表面涂上一层无色透明上光油，经流平、干燥、压光后在印刷品表面形成薄而匀的透明光亮层'
    },
    {
        title: '塑料薄膜底纸',
        explanation: '这种底纸由张度较大的BOPP、PET构成，其特点是尺寸稳定性好，厚度一般为28~60µm'
    },
].map((noun, index) => ({ ...noun, id: index + 1 }));

export function getShuffleNouns(): Noun[] {
    return nouns.sort((a, b) => Math.random() > 0.5 ? a.id - b.id : b.id - a.id);
}
