export interface ShortAnswer {
    desc: string;
    answers: string[];
    id: number;
}

export const shortAnswers: ShortAnswer[] = [
    {
        desc: '简述合成纸应用的方面。',
        answers: ['印刷出版方面', '商业包装方面', '加工纸方面', '建筑材料方面', '其他方面']
    },
    { desc: '简述丝网的编织结构。', answers: ['平织', '斜纹织', '半绞织', '全绞织'] },
    {
        desc: '简述凹版装潢印刷品的外观质量要求。',
        answers: [
            '单色凹版印刷品：亮、中、安调层次分明、协调、细腻，网点清晰，完整 ，版面均匀整洁',
            '彩色凹版印刷品：图像亮、中、暗调层次分明、协调、细腻，颜色自然协调，网点清晰、完整、角度准确，图像轮廓清晰',
            '印刷品外观：版面干净、均匀、无明显脏痕。图像和文字准确。印刷接版色调基本一致。精细印刷品尺寸误差不大于0.5mm，一般印刷不大于1.0mm。正反面套误差不大于1.0mm'
        ]
    },
    { desc: '简述胶印印刷常用的纸张。', answers: ['胶印新闻纸', '胶印印刷纸', '铜版纸', '白板纸'] },
    {
        desc: '简述丝网印刷的印刷适性与特点。',
        answers: ['墨层厚', '承印材料范围广', '对油墨的适应性强', '印刷压力小']
    },
    {
        desc: '简述凹印的网穴结构分类。',
        answers: ['大小相同，深度不同', '大小不同，深度相同', '大小深度均不同']
    },
    {
        desc: '简述塑料表面处理常用的方法。',
        answers: [
            '一般方法：表面清理。脱脂去油。表面粗化。清洁干燥',
            '化学方法是通过化学反应使塑料表面引入活性基团酸蚀性。预涂偶联性',
            '物理方法：放电处理。 火焰处理。辐射处理'
        ]
    },
    { desc: '简述柔性版油墨主要组成部分。', answers: ['色料', '连接料', '助剂'] },
    { desc: '简述丝网版的感光制作方法。', answers: ['直接法', '间接法', '直间法'] },
    {
        desc: '简述白卡纸制作的手提袋特点。',
        answers: [
            '强度高，白卡纸手提袋的强度是所有手提袋中最高的，这是由白卡纸的物理性能决定的，设计师一般将这种手提袋用于盛放高档服装或商品',
            '纸质细腻，与白板纸手提袋相比，白卡纸手提袋手感明显要细腻，因此，显得特别高雅',
            '印刷适性好，白卡纸具有良好的印刷适性，设计师可大胆应用各种设计手法',
            '括色彩构思）',
            '成本高，白卡纸手提袋是手提袋中成本最高的一种'
        ]
    },
    {
        desc: '简述塑料薄膜处理效果的检验方法。',
        answers: [
            '可湿性检验。让水流过塑料薄膜表面，未处理的表面排斥水；已处理的表面保留水分达几分钟之久。处理不完全的表面则出现黏着力有好有坏的不同区域',
            '透明带检验。在已印过油墨的薄膜图文表面，牢固地贴上胶带，然后再迅速扯下，记录胶带粘走的油墨量。合乎要求的是油墨不被粘走',
            '黏着力剥离检验。用辊把指定的压敏胶带贴压在薄膜表面上，用张力计测其剥离强度。薄膜处理的效果越好，其剥离强度越大',
            '揉搓检验。用手折皱和揉搓一会儿印过的薄膜，如果油墨没有出现龟裂或脱落现象，说明黏着力是合乎要求的'
        ]
    },
    {
        desc: '简述胶印印刷成为目前主流印刷方式的主要原因。',
        answers: ['生产效率高', '印刷质量高', '纸张的定量范围大', '生产批量灵活、成本低']
    },
    { desc: '简述直线纸盒的常见形式。', answers: ['套桶式纸盒', '插入式纸盒', '黏合纸盒'] },
    { desc: '简述复合包装的特点。', answers: ['卫生', '方便', '保鲜', '经济'] },
    { desc: '简述模切设备的类型。', answers: ['圆压圆型', '圆压平型', '平压平型'] },
    {
        desc: '简述选用复合纸板印刷时，设计上要注意的问题。',
        answers: [
            '设计底色时，不应全部把底色遮盖，应将金属底色多露出一些',
            '如果有四色叠印网点图像，应该设计为小面积为佳，不宜使用大面积的四色叠印',
            '对色块印刷应尽力设计为专色印刷，因为大面积的叠印色块干燥较慢',
            '印刷时，小面积的色块，文字、线条，颜色浅的先印，面积大的颜色后印',
            '有四色彩色连续调图像，要先打底，打底用油墨的遮盖力要高'
        ]
    },
    {
        desc: '简述铜版纸手提袋的特点。',
        answers: [
            '选择铜版纸手提袋，其特点是牢度适中',
            '由于铜版纸具有较高的白度与光泽度，印刷适性良好',
            '设计师可大胆采用各种画面及色块，广告效果良好',
            '在铜版纸表面覆上光膜或亚光膜后，不但具有防潮、耐用功能，而且显得更精致',
            '铜版纸是一种最普及的手提袋制作材料'
        ]
    },
    {
        desc: '简述在设计选用合成纸时需要特别注意的事项。',
        answers: [
            '合成纸的耐折度虽然优于普通纸，但是一旦折叠后会有难以消失的折线纹，有损印刷品的美观。因此印刷时要注意尺寸，不宜过大',
            '合成纸张用后回收困难，会造成环境污染',
            '合成纸的成本高于普通纸'
        ]
    },
    { desc: '简述丝网版的感光制作方法。', answers: ['直接法', '间接法', '直间法'] },
    {
        desc: '简述柔性版装潢印刷品外观要求。',
        answers: [
            '印刷成品整洁，无明显脏污、残缺',
            '文字印刷清晰完整、无缺笔断划，小于5号字不误字意',
            '不允许存在明显的条杠，无糊版',
            '图面色泽鲜艳、版面均匀、整洁',
            '网点清晰完整，与原稿无明显差别'
        ]
    },
    {
        desc: '试述UV油墨优点。',
        answers: [
            'UV油墨是一种紫外线干燥油墨，优点主要有：',
            '油墨不含溶剂，不污染空气，干燥时不需要热源。所以特别适合多色高速印刷',
            '印迹表面强度高，光泽度好，提高了印刷产品的档次',
            '耐摩擦、色彩牢固性强、耐酸、耐碱、耐乙醇、抗划伤',
            '由于UV墨印在承印物上渗透少，用墨量比普通油墨少，因此用墨可节省',
            '用UV油墨上光，其成本约为覆膜上光的1/5，上光后的外观质量比普通的覆膜上光强得多',
            '承印范围非常广，有塑料薄膜、铝箔、纸张等'
        ]
    },
    {
        desc: '试述复合包装材料铝箔的特点',
        answers: [
            '质轻，具有金属光泽，遮光性好，对热和光有较高的反射能力',
            '隔绝性好，保持性强。不透气体和水气，防止内装物吸潮、氧化。不易受细菌，霉菌和昆虫的侵害',
            '形状稳定性好，不受温度变化的影响',
            '易于加工，可对铝箔进行印刷、着色、压花、表面涂布、上胶、上漆等',
            '不能受力，无封缄性，有针孔和易起皱，故一般不单独使用'
        ]
    },
    {
        desc: '试述胶印印刷中的印墨准备。',
        answers: [
            '胶印油墨主要有亮光油墨、快固着胶印油墨及转轮胶印油墨',
            '第一种油墨光泽度高、颜色鲜艳，主要用于铜版纸、白卡纸、轻涂纸等有涂层的高级纸张，是平张多色印刷机最常用的油墨',
            '第二种油墨固着性好，适用于一般纸张的印刷',
            '第三种油墨可用于印刷速度高达24000r/h的卷筒纸转轮胶印机',
            '彩色印刷有常规的四色印刷和专色印刷，印墨准备的重点是检查专色油墨的准备情况'
        ]
    },
    {
        desc: '试述平版印刷工艺特点。',
        answers: [
            '制版工作简便，成本低廉',
            '套色装版准确，印刷版复制容易',
            '印刷物柔和软调。可以承印大数量印刷',
            '色调再现力低，缺乏鲜艳度',
            '版面油墨稀薄，特殊印刷应用有限'
        ]
    },
    {
        desc: '试述印刷铜版纸的主要性能。',
        answers: [
            '纸质纤维分布均匀，薄厚一致伸缩性小',
            '白度较高，有较好的弹性和较强的抗水性和扩张性能',
            '对油墨的吸收性与接收状态良好，要选用胶印树脂型油墨以及亮光油墨',
            '纸面光洁，光泽度好，平滑度高，表面强度高，印刷性能好，印刷后图像清晰、光彩悦目',
            '还要防止背面粘脏，一般采用防脏、喷粉或夹衬纸'
        ]
    },
    {
        desc: '试述胶印印刷原理。',
        answers: [
            '平版胶印印刷是利用水与油不相溶的原理',
            '在印版上图文部分与非图文部分几乎处于同一平面，通过化学处理使图文部分具有亲油性，非图文部分具有亲水性',
            '印刷过程中，由于润版液的作用，在印版的非图文部分的表面形成具有一定密度的水膜以抗拒油墨浸润',
            '而图文部分由于被处理而具有亲油的性能，在油墨的作用下，图文部分表面形成一定密度的墨膜',
            '在印刷压力作用下，印版上的油墨先转印到橡胶滚筒上，然后经橡胶滚筒转印到纸上'
        ]
    },
    {
        desc: '试述样本印刷中的注意事项。',
        answers: [
            '样本跨页印刷时，必须要求规格统一，色调一致',
            '跨两页以上的，以中间一页色调为印刷基准',
            '帖数多，按顺序印刷',
            '在同页面印刷时，如不能保持全色调统一时，可考虑局部印刷',
            '样本印刷时，尽量避免用辅助材料，以保证印刷质量'
        ]
    },
    {
        desc: '论述纸包装在包装材料中独特优势的主要表现。',
        answers: [
            '材料成本低',
            '能适应各种造型设计',
            '印刷制作方便',
            '适应面广',
            '经过精心设计制作的产品能应用于各种档次的商品包装'
        ]
    },
    {
        desc: '论述印刷胶版纸的主要性能。',
        answers: [
            '伸缩性小，对油墨的吸收性均匀',
            '平滑度好，质地紧密不透明',
            '白度好，抗水性能强',
            '应选用结膜型胶印油墨和质量较好的铅印油墨',
            '油墨的黏度也不宜过高，否则会出现脱粉、拉毛现象'
        ]
    },
    {
        desc: '试述凹版印刷品的质量要求。',
        answers: [
            '单色凹版印刷品，亮、中、暗调层次分明、协调、细腻，网点清晰，完整，版面均匀整洁',
            '彩色凹版印刷品，图像亮、中、暗调层次分明、协调、细腻，颜色自然协调，网点清晰、完整、角度准确，图像轮廓清晰',
            '印刷品外观，版面干净、均匀、无明显脏痕。图像和文字的位置准确。印刷接版色调基本一致',
            '精细印刷品尺寸误差不大于0.5mm，一般印刷品不大于1.0mm。正反面套印误差不大于1.0mm'
        ]
    },
    {
        desc: '试述凹版装潢印刷品的外观质量要求。',
        answers: [
            '成品整洁，无明显脏污、残缺、刀丝',
            '文字印刷清晰完整，5号字以下不误字意',
            '印迹边缘光洁',
            '网纹清晰均匀，无明显变形和残缺'
        ]
    },
    {
        desc: '试述塑料薄膜处理效果的检验方法。',
        answers: [
            '可湿性检验。让水流过塑料薄膜表面，未处理的表面排斥水；已处理的表面保留水分达几分钟之久。处理不完全的表面则出现黏着力有好有坏的不同区域',
            '透明带检验。在已印过油墨的薄膜图文表面，牢固地贴上胶带，然后再迅速扯下，记录胶带粘走的油墨量。合乎要求的是油墨不被粘走',
            '黏着力剥离检验。用辊把指定的压敏胶带贴压在薄膜表面上，用张力计测其剥离强度。薄膜处理的效果越好，其剥离强度越大',
            '揉搓检验。用手折皱和揉搓一会儿印过的薄膜，如果油墨没有出现龟裂或脱落现象，说明黏着力是合乎要求的'
        ]
    },
    {
        desc: '试述丝网印刷的印刷适性与特点。',
        answers: [
            '墨层厚。丝网印刷的墨层厚度可达20微米，墨层厚的优点是具有立体感，墨色实，色彩突出',
            '承印材料范围广。丝网印刷在各种材质上均能印刷，如塑料、金属、玻璃等材料上，并且能在各种形状、大小的承印物上印刷',
            '对油墨的适应性强。由于丝网印刷采用漏印方式，相对来说对油墨的要求不是很严格，液状油墨、粉状油墨、水性油墨、油性油墨均可采用',
            '印刷压力小。丝网印刷版柔软并富有弹性，印刷压力较小，可以在易损坏的物体上印刷'
        ]
    },
    {
        desc: '试述白卡纸制作的手提袋特点。',
        answers: [
            '强度高，白卡纸手提袋的强度是所有手提袋中最高的，这是由白卡纸的物理性能决定的，设计师一般将这种手提袋用于盛放高档服装或商品',
            '纸质细腻，与白板纸手提袋相比，白卡纸手提袋手感明显要细腻，因此，显得特别高雅',
            '印刷适性好，白卡纸具有良好的印刷适性，设计师可大胆应用各种设计手法（包括色彩构思）',
            '成本高，白卡纸手提袋是手提袋中成本最高的一种'
        ]
    }
].map((shortAnswer, index) => ({ ...shortAnswer, id: index + 1 }));

export function getShuffleShortAnswers(): ShortAnswer[] {
    return shortAnswers.sort((a, b) => Math.random() > 0.5 ? a.id - b.id : b.id - a.id);
}
